import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { useTransition, animated } from 'react-spring'

import ArrowBack from "../images/arrow_back.svg";
import ArrowForward from "../images/arrow_forward.svg";

const TRANSITIONS = {
    from: {  opacity: 0, transform: 'translate3d(100%,0%,0)'},
    enter: { opacity: 0.7, transform: 'translate3d(0%,0,0)'},
    leave: { opacity: 0.9, transform: 'translate3d(-50%,0%,0)'},
    config: { duration: 850}
}
const TRANSITIONSCenter = {
    from: {  opacity: 0, transform: 'translate3d(100%,0%,0)'},
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)'},
    leave: { opacity: 1, transform: 'translate3d(-50%,0%,0)'},
    config: { duration: 850}
}
const ImageSimpleGallery = ({imageList}) => {
    const [indexImage1, setIndexImage1] = useState(imageList.length - 1)
    const [indexImage2, setIndexImage2] = useState(0)
    const [indexImage3, setIndexImage3] = useState(1)
    const [images, setImages] = useState([])

    useEffect(() => {
        setImages(imageList.map(image => (
            ({ style }) => 
                <animated.div style={{ ...style, backgroundRepeat:'no-repeat', position:'relative', height: '100%', width: '100%', backgroundImage:`url(${image.image})` }}>
                    <div className="image-gallery__text-slider" >
                        <span dangerouslySetInnerHTML={{ __html: image.text}} />
                    </div>
                </animated.div>
        )))
    }, [imageList]);

    const onClickBack = () => {
        setIndexImage1(state => (state + 1) % images.length)
        setIndexImage2(state => (state + 1) % images.length)
        setIndexImage3(state => (state + 1) % images.length)
    }
    const onClickForward = () => {
        setIndexImage1(state => state === 0 ? images.length - 1 : state - 1)
        setIndexImage2(state => state === 0 ? images.length - 1 : state - 1)
        setIndexImage3(state => state === 0 ? images.length - 1 : state - 1)
    }
    const transitions1 = useTransition(indexImage1, p => p, TRANSITIONS)
    const transitions2 = useTransition(indexImage2, p => p, TRANSITIONSCenter)
    const transitions3 = useTransition(indexImage3, p => p, TRANSITIONS)


    if (!images.length) {
        return <div></div>
    }

    return (
        <div className="image-gallery image-gallery__single">
            <div className="simple-trans-sides">
                {transitions1.map(({ item, props, key }) => {
                    const Image = images[item]
                    return <Image key={key} style={props} />
                })}
            </div>
            <div className="simple-trans-center">
                {transitions2.map(({ item, props, key }) => {
                    const Image = images[item]
                    return <Image key={key} style={props} />
                })}
            </div>
            <div className="simple-trans-sides">
                {transitions3.map(({ item, props, key }) => {
                    const Image = images[item]
                    return <Image key={key} style={props} />
                })}
            </div>
            <div className="image-gallery__single__button-group">
                <button className="image-gallery__button" onClick={onClickForward}>
                    <img src={ArrowBack} alt="back" />
                </button>
                <button className="image-gallery__button" onClick={onClickBack}>
                    <img src={ArrowForward} alt="forward" />
                </button>
            </div>
        </div>
    )
}

ImageSimpleGallery.propTypes = {
    imageList: PropTypes.array.isRequired,
}

export default ImageSimpleGallery