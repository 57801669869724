import React from 'react'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'
import Flip from 'react-reveal/Flip'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ImageGallery from '../components/imageGallery'
import ImageSimpleGallery from '../components/imageSimpleGallery'

import ImageHistory from '../images/img-historia-3.png'
import ImageHistory2 from '../images/img-historia-3@2x.png'
import ImageHistory3 from '../images/img-historia-3@3x.png'

import ImageArcar from '../images/arcar-wide.png'
import ImageArcar2 from '../images/arcar-wide@2x.png'
import ImageArcar3 from '../images/arcar-wide@3x.png'

import ImageIntersection from '../images/intersection-1.svg'
import ImageIntersection9 from '../images/intersection-9.svg'

// import ImgGallery1 from "../images/galeria-4-1_Img.jpg"
// import ImgGallery2 from "../images/galeria-4-2_Img.jpg"
// import ImgGallery3 from "../images/galeria-4-3_Img.jpg"
// import ImgGallery4 from "../images/galeria-4-4_Img.jpg"

import ImgGallery5 from '../images/gallery-history/Careyes_ChacMol-2.jpg'
import ImgGallery6 from '../images/gallery-history/Careyes_ChacMol-3.jpg'
import ImgGallery7 from '../images/gallery-history/Careyes_ChacMol.jpg'
import ImgGallery8 from '../images/gallery-history/Careyes_Clausura-1.jpg'
import ImgGallery9 from '../images/gallery-history/Careyes_Cocodrilo-1.jpg'
import ImgGallery10 from '../images/gallery-history/Careyes_Cocodrilo-2.jpg'
// import ImgGallery11 from "../images/gallery-history/Careyes_Copa-1.jpg"
// import ImgGallery12 from "../images/gallery-history/Careyes_Copa-2.jpg"
import ImgGallery13 from '../images/gallery-history/Careyes_Copa-3.jpg'
import ImgGallery14 from '../images/gallery-history/Careyes_Dia2-1.jpg'
import ImgGallery15 from '../images/gallery-history/Careyes_Dia2-2.jpg'
// import ImgGallery16 from "../images/gallery-history/Careyes_Dia2-3.jpg"
// import ImgGallery17 from "../images/gallery-history/Careyes_Dia2-4.jpg"
import ImgGallery18 from '../images/gallery-history/Careyes_Dia2-5.jpg'
import ImgGallery19 from '../images/gallery-history/Careyes_Dia2-6.jpg'
import ImgGallery20 from '../images/gallery-history/Careyes_Dia2-7.jpg'
// import ImgGallery21 from "../images/gallery-history/Careyes_Dia2-8.jpg"
import ImgGallery22 from '../images/gallery-history/MB-1.jpg'
import ImgGallery23 from '../images/gallery-history/MB-2.jpg'
// import ImgGallery24 from "../images/gallery-history/MB-3.jpg"
import ImgGallery25 from '../images/gallery-history/MB-4.jpg'
import ImgGallery26 from '../images/gallery-history/MB-5.jpg'
// import ImgGallery27 from "../images/gallery-history/MB-6.jpg"
import ImgGallery28 from '../images/gallery-history/MB-7.jpg'
// import ImgGallery29 from "../images/gallery-history/Neg_88.jpg"
import ImgGallery30 from '../images/gallery-history/Neg-2.jpg'
import ImgGallery31 from '../images/gallery-history/Neg-3.jpg'
import ImgGallery32 from '../images/gallery-history/Neg-5.jpg'
import ImgGallery33 from '../images/gallery-history/Polo-1.jpg'
import ImgGallery34 from '../images/gallery-history/Polo-2.jpg'
//import ImgGallery35 from "../images/gallery-history/Pool-1.jpg"
// import ImgGallery36 from "../images/gallery-history/Pool-2.jpg"
// import ImgGallery37 from "../images/gallery-history/Pool-3.jpg"
// import ImgGallery38 from "../images/gallery-history/Pool-4.jpg"
import ImgGallery39 from '../images/gallery-history/Pool-5.jpg'
import ImgGallery40 from '../images/gallery-history/PR-1.jpg'
import ImgGallery41 from '../images/gallery-history/PR-2.jpg'
import ImgGallery42 from '../images/gallery-history/Samadi-1.jpg'
import ImgGallery43 from '../images/gallery-history/Samadi-2.jpg'
// import ImgGallery44 from "../images/gallery-history/Samadi-3.jpg"
import ImgGallery45 from '../images/gallery-history/Samadi-4.jpg'
import ImgGallery46 from '../images/gallery-history/Samadi-5.jpg'
//import ImgGallery47 from "../images/gallery-history/Samadi-6.jpg"
import ImgGallery48 from '../images/gallery-history/Samadi-7.jpg'
import ImgGallery49 from '../images/gallery-history/Samadi-8.jpg'
//import ImgGallery50 from "../images/gallery-history/Samadi-9.jpg"
import ImgGallery51 from '../images/gallery-history/Samadi-10.jpg'
import ImgGallery52 from '../images/gallery-history/Samadi-11.jpg'
import ImgGallery53 from '../images/gallery-history/Samadi-12.jpg'
//import ImgGallery54 from "../images/gallery-history/Samadi-13.jpg"
import ImgGallery55 from '../images/gallery-history/Samadi-14.jpg'
import ImgGallery56 from '../images/gallery-history/Samadi-15.jpg'
import ImgGallery57 from '../images/gallery-history/Diego_Luna.jpg'

import ImgGalleryTribute1 from '../images/tributo-1-2019.jpg'
import ImgGalleryTribute2 from '../images/tributo-2-2018.jpg'
import ImgGalleryTribute3 from '../images/tributo-3-2017.jpg'
import ImgGalleryTribute4 from '../images/tributo-4-2016.jpg'
import ImgGalleryTribute5 from '../images/tributo-5-2015.jpg'
import ImgGalleryTribute6 from '../images/tributo-6-2014.jpg'
import ImgGalleryTribute7 from '../images/tributo-7-2013.jpg'

import { PAST_EDITIONS, HISTORY_PLACEHOLDERS } from '../constants'

const History = () => (
  <Layout
    gradient={{
      background:
        'linear-gradient(to bottom, rgba(32, 41, 69, 0.85), rgba(32, 41, 69, 0))',
    }}
  >
    <SEO title="Historia" />
    <div className="history__main-image">
      <header>
        <h1 className="main-image__header">Historia</h1>
        <h1 className="main-image__header">e inspiracion</h1>
        <h1 className="main-image__header">de Arte Careyes</h1>
      </header>
    </div>
    <section className="history__description" id="about">
      <Fade duration={2000}>
        <div className="description__text">
          <p>
            Arte Careyes nace en 2010 como un proyecto para generar conciencia y
            apoyo a las industrias creativas mexicanas.
          </p>
          <p>
            El esfuerzo detrás de este festival nos ha permitido contribuir a la
            conservación de la naturaleza y, a su vez, impulsar a artistas
            emergentes en distintas disciplinas culturales.
          </p>
        </div>
      </Fade>

      <Fade duration={2000} delay={200}>
        <img
          src={ImageHistory}
          srcSet={`${ImageHistory2} 2x,
                        ${ImageHistory3} 3x`}
          className="description__image"
          alt="playa"
        />
      </Fade>
    </section>
    <section className="history__tribute-section" id="tribute">
      <img
        src={ImageIntersection}
        className="tribute-section__image-decorator"
        alt="decorator"
      />
      <Fade duration={2000}>
        <h1 className="tribute-section__title">Tributo TANE</h1>
        {/* <ImageGallery
                imageList={[ImgGalleryTribute1, ImgGalleryTribute2, ImgGalleryTribute3,
                    ImgGalleryTribute4, ImgGalleryTribute5,
                    ImgGalleryTribute6, ImgGalleryTribute7
                ]}
                placeholderList={HISTORY_PLACEHOLDERS.map(year => (
                    <p className="image-gallery__placeholder-text">
                        <b>{year.textBold}</b>
                        &nbsp;{year.textNormal}
                    </p>
                ))}
            /> */}
        <ImageSimpleGallery
          imageList={[
            { image: ImgGalleryTribute1, text: '<b>2019</b> MARC VALLEE' },
            { image: ImgGalleryTribute2, text: '<b>2018</b> JR' },
            { image: ImgGalleryTribute3, text: '<b>2017</b> LEE DANIELS' },
            { image: ImgGalleryTribute4, text: '<b>2016</b> DIEGO LUNA' },
            {
              image: ImgGalleryTribute5,
              text: '<b>2015</b> GUILLERMO ARRIAGA',
            },
            { image: ImgGalleryTribute6, text: '<b>2014</b> MICHAEL NYMAN' },
            { image: ImgGalleryTribute7, text: '<b>2013</b> CARLOS CUARON' },
          ]}
        />
      </Fade>
      <Fade left>
        <img
          src={ImageArcar}
          srcSet={`${ImageArcar2} 2x,
                        ${ImageArcar3} 3x`}
          className="tribute-section__tribute-image"
          alt="arcar 2017"
        />
      </Fade>
      {/* <ImageGallery imageList={[ImgGalleryTribute1, ImgGalleryTribute2, ImgGalleryTribute3, ImgGalleryTribute4, ImgGalleryTribute5, ImgGalleryTribute6, ImgGalleryTribute7]}/> */}
    </section>
    <section className="history__past-editions" id="past-editions">
      <header>
        <h1 className="past-editions__header">Ediciones</h1>
        <h1 className="past-editions__subheader">anteriores</h1>
      </header>
      <div className="past-editions__grid">
        {PAST_EDITIONS.map(({ title, imageSet }) => (
          <div className="grid" key={title}>
            <Fade cascade duration={2000}>
              <img
                src={imageSet[0]}
                srcSet={`${imageSet[1]} 2x,
                                    ${imageSet[2]} 3x`}
                className="grid__image"
                alt={title}
              />
              <p className="grid__text">{title}</p>
            </Fade>
          </div>
        ))}
      </div>
    </section>
    <Fade>
      <section className="history__gallery" id="gallery">
        <img
          src={ImageIntersection9}
          alt="decorator"
          className="gallery__image-decorator"
        />
        <h3 className="gallery__header">Galería</h3>
        <ImageGallery
          imageList={[
            ImgGallery5,
            ImgGallery6,
            ImgGallery7,
            ImgGallery8,
            ImgGallery9,
            ImgGallery10,
            ImgGallery13,
            ImgGallery14,
            ImgGallery15,
            ImgGallery18,
            ImgGallery19,
            ImgGallery20,
            ImgGallery22,
            ImgGallery23,
            ImgGallery25,
            ImgGallery26,
            ImgGallery28,
            ImgGallery30,
            ImgGallery31,
            ImgGallery32,
            ImgGallery33,
            ImgGallery34,
            ImgGallery39,
            ImgGallery40,
            ImgGallery41,
            ImgGallery42,
            ImgGallery43,
            ImgGallery45,
            ImgGallery46,
            ImgGallery48,
            ImgGallery49,
            ImgGallery51,
            ImgGallery52,
            ImgGallery53,
            ImgGallery55,
            ImgGallery56,
            ImgGallery57,
          ]}
        />
      </section>
    </Fade>
  </Layout>
)

export default History
